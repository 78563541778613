





































































import {Component, Vue} from "vue-property-decorator";
import BaseOverview from "@/resources/components/BaseOverview.vue";
import Client from '@/app/Http/Client';
import Relation from "@/app/models/Relation";

@Component({
    components: {
        'base-overview': BaseOverview,
    }
})
export default class Show extends Vue {
    documents: [] = [];
    categories: [] = [];
    invoices: [] = [];
    relation: Relation | null = null;

    beforeMount() {
        this.fetchDocuments();
        this.fetchRelation();
        this.fetchCategories();
        this.fetchInvoices();
    }

    mounted() {
        this.$root.$on('categoryAdded', (category: any) => {
            (this.$refs.categoryOverview as BaseOverview).items.push(category);
        })
    }

    fetchDocuments() {
        const documentUrl = [
            'relations',
            this.$route.params.relationId ?? null,
            'documents',
            this.$route.params.categoryId ?? null,
        ].filter((value) => {
            return value != null;
        }).join('/')

        Client.get(documentUrl)
            .then(response => {
                this.documents = (response.data as any).data
            });
    }

    async fetchRelation() {
        const relationId = parseInt(this.$route.params.relationId);
        if (isNaN(relationId)) {
            this.relation = new Relation({name: 'Overig'});
        }
        this.relation = await Relation.show(relationId) as Relation;
    }

    fetchCategories() {
        const categoryUrl = [
            'relations',
            this.$route.params.relationId ?? null,
            'categories',
            this.$route.params.categoryId ?? null,
        ].filter((value) => {
            return value != null;
        }).join('/')


        Client.get(categoryUrl)
            .then(response => {
                this.categories = (response.data as any).data
            });
    }

    fetchInvoices() {
        const categoryUrl = [
            'relations',
            this.$route.params.relationId ?? null,
            'invoices',
        ].filter((value) => {
            return value != null;
        }).join('/')

        Client.get(categoryUrl)
            .then(response => {
                this.invoices = (response.data as any).data
            });
    }

    downloadDocument(dowloadable: any) {
        Client.get(`documents/${dowloadable.id}/download`, {responseType: 'blob'})
            .then((response: any) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', dowloadable.name); //any other extension

                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    }

    upload(event: Event) {
        if (event.target === null) {
            return;
        }

        const category = this.$route.params.categoryId ?? null;
        let formData = new FormData()
        Object.values((event.target as HTMLInputElement).files as object).map((file: File) => {
            formData.append('documents[]', file)
        });

        if (category !== null) {
            formData.append('categoryId', category)
        }

        Client.post(`/relations/${this.$route.params.relationId}/documents`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(() => this.fetchDocuments())
    }

    getCategoryUrl(id: number) {
        return `/documenten/${this.$route.params.relationId}/${id}`;
    }
}
