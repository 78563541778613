


























import {Component, Emit, Vue} from "vue-property-decorator";
import BaseOverview from "@/resources/components/BaseOverview.vue";
import InfiniteLoading from "vue-infinite-loading";

export interface Loadable {
    loadItems: (items: any[]) => void
    page: number
}

@Component({
    components: {
        'base-overview': BaseOverview,
        'infinite-loading': InfiniteLoading,
    },
})
export default class InfiniteOverview extends Vue {
    page: number = 1;
    items: any[] = [];

    $refs!: {
        infiniteLoading: InfiniteLoading;
    };

    @Emit("load-items")
    loadItems({loaded, complete}: any): Loadable {
        return {
            loadItems: (items: any[]): void => {
                if (typeof items === 'undefined' || items.length === 0) {
                    complete();
                }
                this.items = this.items.concat(items);
                loaded();
                this.page++;
            },
            page: this.page,
        };
    }

    public reset() {
        this.page = 1;
        this.items = [];
        this.$refs.infiniteLoading.stateChanger.reset();
    }
}
