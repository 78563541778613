import kebabCase from 'lodash/kebabCase'
import pluralize from 'pluralize';
import PaginatedResponse from "@/app/models/Concerns/PaginatedResponse";
import {AxiosResponse} from "axios";
import Client from "@/app/Http/Client";

export default abstract class Model {
    dates?: string[]
    public id!: number | null;
    public created_at!: string | null
    public updated_at!: string | null

    constructor(data: { [key: string]: any }) {
        this.setProperties(data);
    }

    public static get table(): string {
        const constructor = this as Function;
        // @ts-ignore
        const table = (new constructor({})).getTable();

        return kebabCase(pluralize(table));
    }

    public static async index(page: number = 1, params: { [key: string]: any } = {}, url: string = this.table): Promise<PaginatedResponse<Model>> {
        const response: AxiosResponse<{ o: { [s: string]: number | string | null } }> = await Client.get(url, {
            params: {
                page,
                ...params,
            },
        });

        return new PaginatedResponse<Model>(response.data, this);
    }

    public static async show(id: number): Promise<Model> {
        const constructor = this as Function;
        const response: AxiosResponse<{ data: Model }> = await Client.get([this.table, id].join('/'));

        // @ts-ignore
        return new constructor(response.data.data);
    }

    public static async store(data: any): Promise<Model> {
        const constructor = this as Function;
        const response: AxiosResponse<{ data: Model }> = await Client.post(this.table, data);

        // @ts-ignore
        return new constructor(response.data.data);
    }

    public static async update(data: any, id: number): Promise<Model> {
        const constructor = this as Function;
        const response: AxiosResponse<{ data: Model }> = await Client.patch([this.table, id].join('/'), data);

        // @ts-ignore
        return new constructor(response.data.data);
    }

    public static destroy(id: number): Promise<boolean> {
        return Client.delete([this.table, id].join('/'))
            .then(() => true)
            .catch(() => false);
    }

    public abstract getTable(): string;

    public async destroy() {
        // @ts-ignore
        return await this.constructor.destroy(this.id);
    }

    public async save(): Promise<Model> {
        // @ts-ignore
        await this.constructor[
            this.id == null
                ? "store"
                : "update"
            ](this, this.id).then(
            (model: Model) => {
                this.setProperties(model);
            }
        );
        return this;
    }

    private setProperties(data: { tabs: any[] } | { [key: string]: any }) {
        let parsed = Object.entries(data).map((entry: any[]) => {
            if ((this.dates || []).concat(['created_at', 'updated_at']).includes(entry[0]) && entry[1] != null) {
                entry[1] = new Date(entry[1]);
            }
            return entry;
        });

        Object.assign(this, Object.fromEntries(parsed));
    }
}
