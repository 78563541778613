































import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class BaseOverview extends Vue {
    @Prop({required: true}) items!: any[];
}
