import Model from "@/app/models/Concerns/Model";

export default class Relation extends Model {
    id!: number
    exactCode!: string
    logic4Id!: number
    name!: string

    getTable(): string {
        return "relations";
    }
}
