



















































import {Component, Vue, Watch} from "vue-property-decorator";
import BaseOverview from "@/resources/components/BaseOverview.vue";
import InfiniteOverview, {Loadable} from "@/resources/components/InfiniteOverview.vue";
import Relation from "@/app/models/Relation";
import Debounce from "@/app/decorators/debounce";

@Component({
    components: {
        InfiniteOverview,
        "base-overview": BaseOverview,
    },
})

export default class DocumentsIndex extends Vue {
    relations: any[] = [];
    searchInput: string = '';

    async loadItems(loadable: Loadable) {
        const response = await Relation.index(loadable.page, {search: this.searchInput});

        loadable.loadItems(response.data);
    }

    @Watch('$route')
    setOverflow(to: any, from: any) {
        const style = to.name !== 'documents.index'
            ? 'hidden'
            : 'auto';

        document.body.style.overflow = style;
    }

    @Watch('searchInput')
    @Debounce(250)
    onSearchUpdated() {
        (this.$refs.overview as InfiniteOverview).reset();
    }
}
