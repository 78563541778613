export default class PaginatedResponse<T> {
    links!: {
        first: string,
        last: string,
        next: string | null,
        prev: string | null
    };
    meta!: {
        current_page: number,
        from: number,
        last_page: number,
        path: string
        per_page: number,
        to: number,
        total: number,
    };
    data!: T[];

    constructor(data: { [key: string]: { [p: string]: any } }, constructor: Function) {
        Object.entries(data)
            .forEach((entry: ArrayLike<any>) => {
                let key: string = entry[0] as string;
                let value: any = entry[1];

                if (key === 'data') {
                    // @ts-ignore
                    value = value.map((item) => new constructor(item));
                }

                // @ts-ignore
                this[key] = value;
            });
    }
}